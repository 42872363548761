/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Flex, Box, Grid, Card, Heading, Text, Image } from 'theme-ui';
import { Link, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';

import Breadcrumbs from '../../../plugins/storefront/src/components/Breadcrumbs';

const GuidesPage = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  return (
    <>
      <Helmet
        title={t({
          id: `Guides.Title`,
          message: `Guides`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `Guides.MetaDescription`,
            message: `Hair Extensions Tape-ins Monotapes Guides`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            productTitle={t({
              id: `Guides.BreadcrumbsTitle`,
              message: `Guides`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Box
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 1300,
        }}
      >
        <Heading as="h1" sx={{ fontSize: [4, 5] }} mb={[3, 4]}>
          <Trans id="Guides.Header">Guides</Trans>
        </Heading>

        <Grid columns={[1, 2, 3]}>
          <Flex sx={{ flexDirection: 'column' }}>
            <Link to="/guides/how-to-choose-hair-color">
              <StaticImage
                sx={{ width: '100%', height: 300 }}
                alt={t({
                  id: `Guides.HowToChooseHairColorImageAlt`,
                  message: `How to choose hair monotapes color`,
                })}
                placeholder="blurred"
                layout="constrained"
                src="../../images/guides/how-to-choose-color.jpg"
              />
            </Link>

            <Heading
              sx={{ fontSize: 4 }}
              as={Link}
              variant="link"
              to="/guides/how-to-choose-hair-color"
            >
              <Trans id="Guides.HowToChooseHairColorHeader">
                How to choose color
              </Trans>
            </Heading>
            <Text>
              <Trans id="Guides.HowToChooseHairColorDescription">
                How to match Kisscurls Hair Monotapes color with your hair.
              </Trans>
            </Text>
          </Flex>

          <Flex sx={{ flexDirection: 'column' }}>
            <Link to="/guides/how-to-choose-hair-size">
              <StaticImage
                sx={{ width: '100%', height: 300 }}
                alt={t({
                  id: `Guides.HowToChooseHairSizeImageAlt`,
                  message: `Hair Monotapes Size Chart`,
                })}
                placeholder="blurred"
                layout="constrained"
                src="../../images/guides/how-to-choose-size.jpg"
              />
            </Link>

            <Heading
              sx={{ fontSize: 4 }}
              as={Link}
              variant="link"
              to="/guides/how-to-choose-hair-size"
            >
              <Trans id="Guides.HowToChooseHairSizeHeader">Size Chart</Trans>
            </Heading>
            <Text>
              <Trans id="Guides.HowToChooseHairSizeDescription">
                How to choose suitable hair length.
              </Trans>
            </Text>
          </Flex>

          <Flex sx={{ flexDirection: 'column' }}>
            <Link to="/guides/hair-care-guide">
              <StaticImage
                sx={{ width: '100%', height: 300 }}
                alt={t({
                  id: `Guides.HairCareGuideImageAlt`,
                  message: `Hair Monotapes Size Chart`,
                })}
                placeholder="blurred"
                layout="constrained"
                src="../../images/guides/hair-care-guide.jpg"
              />
            </Link>

            <Heading
              sx={{ fontSize: 4 }}
              as={Link}
              variant="link"
              to="/guides/hair-care-guide"
            >
              <Trans id="Guides.HairCareGuideHeader">Hair Care Guide</Trans>
            </Heading>
            <Text>
              <Trans id="Guides.HairCareGuideDescription">
                How to take care of your hair extensions.
              </Trans>
            </Text>
          </Flex>

          <Flex sx={{ flexDirection: 'column' }}>
            <Link to="/guides/video-guide">
              <StaticImage
                sx={{ width: '100%', height: 300 }}
                alt={t({
                  id: `Guides.VideoGuideImageAlt`,
                  message: `Hair Monotapes Size Chart`,
                })}
                placeholder="blurred"
                layout="constrained"
                src="../../images/guides/video-guide.jpg"
              />
            </Link>

            <Heading
              sx={{ fontSize: 4 }}
              as={Link}
              variant="link"
              to="/guides/video-guide"
            >
              <Trans id="Guides.VideoGuideHeader">Video guide</Trans>
            </Heading>
            <Text>
              <Trans id="Guides.VideoGuideDescription">
                See our video tutorial on installing and replacing tape-in
                monotapes.
              </Trans>
            </Text>
          </Flex>
        </Grid>
      </Box>
    </>
  );
};

export const GuidesPageQuery = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`;

export default GuidesPage;
